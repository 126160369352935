'use strict';
jQuery(document).ready(function($){
	
	$('#scroll-down').click(function(){
		$('html,body').animate({
			scrollTop: $('.grid-container.home.section-two.fluid').offset().top
		}, 'slow');
	})
 	
	$('#search_form input[name=text]').focus( function(){
        $('#search_form input[name=cpt]').val('425');
    });

    //CONTACT FORM
    $('#search_form')
    // to prevent form from submitting upon successful validation
    .on("submit", function(ev) {
        ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
        $('[name="text"]').val().replace(/^\s+/,"");
        $('[name="text"]').val().replace(" ","");
    })	
    .on("forminvalid.zf.abide", function(ev,frm) {
        console.log("Form id "+ev.target.id+" is invalid");
    })
    .on("formvalid.zf.abide", function(ev,frm) {

            console.log("form is valid");
            $('#search_form .loading').css('display','inline-block');
            if($('#search_form input[name=cpt]').val() == '425') {
			   var searchTerm = $('#search_form input[name=text]').val();
			   window.location.href = '/search/?searchTerm='+searchTerm;
            } 
        });
        //  $('#home-modal').foundation('open');
        // $('#home-modal .close-button').on('click', function(){
        //     $('#home-modal').foundation('close');
        // });
});